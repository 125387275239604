<template>
  <VRow>
    <VCol
      v-for="(taskItem,index) in tasks"
      :key="`task-date-${index}`"
      cols="12"
    >
      <VRow>
        <VCol>
          <div
            class="tt-text-body-2 tt-light-mono-64--text"
            data-test="task-group-date"
          >
            {{ taskItem.date }}
          </div>
        </VCol>
      </VRow>
      <VRow
        v-for="(taskData,idx) in taskItem.data"
        :key="`task-label-${taskData.id}`"
        align="center"
        dense
      >
        <VCol>
          <TTCheckbox
            :color="getCheckboxStyleByState(taskData.state).color"
            :label="taskData.name"
            :on-icon="getCheckboxStyleByState(taskData.state).onIcon"
            hide-details
            class="ma-0"
            :class="{'line--through' : !isPending(taskData.state)}"
            :input-value="(taskData.state!== TRAINEES_TASK_STATUSES['PENDING'])"
            :data-test="`task-item-checkbox-${idx}`"
            @change="onToggleState(taskData.id,taskData.state)"
          />
        </VCol>
        <VCol cols="auto">
          <VMenu
            offset-y
            max-width="250"
            content-class="menu-card"
            left
          >
            <template #activator="{ on }">
              <TTBtn
                fab
                small
                depressed
                color="transparent"
                :ripple="false"
                data-test="task-button-menu-show"
                v-on="on"
              >
                <VIcon size="19">
                  fal fa-ellipsis-h
                </VIcon>
              </TTBtn>
            </template>
            <VList
              dense
            >
              <template v-if="!isPending(taskData.state)">
                <VListItem
                  link
                  data-test="menu-button-pending"
                  @click="onClickStateChange(taskData.id, TRAINEES_TASK_STATUSES['PENDING'])"
                >
                  <VListItemAvatar>
                    <VIcon
                      size="19"
                      color="tt-black"
                    >
                      fal fa-undo
                    </VIcon>
                  </VListItemAvatar>
                  <VListItemContent class="tt-text-body-2">
                    {{ $t('return_to_execution') }}
                  </VListItemContent>
                </VListItem>
                <VDivider inset />
              </template>
              <template v-if="!isCompleted(taskData.state)">
                <VListItem
                  link
                  data-test="menu-button-without-problem"
                  @click="onClickStateChange(taskData.id, TRAINEES_TASK_STATUSES['WITHOUT_PROBLEM'])"
                >
                  <VListItemAvatar>
                    <VIcon
                      size="19"
                      color="tt-black"
                    >
                      fal fa-check
                    </VIcon>
                  </VListItemAvatar>
                  <VListItemContent class="tt-text-body-2">
                    {{ $t('mark_as_completed') }}
                  </VListItemContent>
                </VListItem>
                <VDivider inset />
              </template>
              <VListItem
                v-if="!isFailed(taskData.state)"
                link
                data-test="menu-button-failed"
                @click="onClickStateChange(taskData.id, TRAINEES_TASK_STATUSES['FAILED'])"
              >
                <VListItemAvatar>
                  <VIcon
                    size="19"
                    color="tt-black"
                  >
                    fal fa-times
                  </VIcon>
                </VListItemAvatar>
                <VListItemContent class="tt-text-body-2">
                  {{ $t('mark_as_uncompleted') }}
                </VListItemContent>
              </VListItem>
            </VList>
          </VMenu>
        </VCol>
      </VRow>
    </VCol>
  </VRow>
</template>
<script>
import { TRAINEES_TASK_STATUSES } from '@/helpers/constants';

export default {
  name: 'TraineeTaskList',
  props: {
    tasks: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      TRAINEES_TASK_STATUSES,
    };
  },
  methods: {
    onClickStateChange(id, state) {
      this.$emit('change:state', { id, state });
    },
    onToggleState(id, state) {
      this.$emit('change:state', {
        id,
        state: state === this.TRAINEES_TASK_STATUSES.PENDING
          ? this.TRAINEES_TASK_STATUSES.WITHOUT_PROBLEM : this.TRAINEES_TASK_STATUSES.PENDING,
      });
    },
    isCompleted(state) {
      return state === this.TRAINEES_TASK_STATUSES.WITHOUT_PROBLEM;
    },
    isPending(state) {
      return state === this.TRAINEES_TASK_STATUSES.PENDING;
    },
    isFailed(state) {
      return state === this.TRAINEES_TASK_STATUSES.FAILED;
    },
    getCheckboxStyleByState(state) {
      switch (state) {
        case this.TRAINEES_TASK_STATUSES.PENDING:
        case this.TRAINEES_TASK_STATUSES.WITH_PROBLEM:
        case this.TRAINEES_TASK_STATUSES.WITHOUT_PROBLEM:
          return { onIcon: '$checkboxOn', color: 'tt-light-blue' };
        case this.TRAINEES_TASK_STATUSES.FAILED:
          return { onIcon: 'fas fa-times', color: 'tt-light-red' };
        default:
          return { onIcon: '$checkboxOn', color: 'tt-light-blue' };
      }
    },
  },
};
</script>
<style lang="scss">
.line {
  &--through {
    .checkbox-label {
      text-decoration: line-through;
    }
  }
}
.menu-card {
  border: 1px solid #EBECED !important;
  box-shadow: 0 2px 24px rgba(11, 18, 24, 0.08) !important;
}
</style>
