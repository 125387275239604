<template>
  <div v-if="!isMobile">
    <VContainer
      v-if="user"
      class="pa-0"
    >
      <VRow>
        <VCol>
          <VRow>
            <VCol cols="12">
              <ProfileHeader
                :level="user.levelName"
                :levels-completed="user.levelsCompleted"
                :photo-url="user.photoUrl"
                :first-name="user.firstName"
                :last-name="user.lastName"
                :position="user.staffPosition"
                :score="user.stat.bonus"
              />
            </VCol>
            <VCol cols="12">
              <TabsWrapper
                v-model="selectedTab"
                :tabs="activeTabNames"
                data-test="tab-items"
                underline="none"
              >
                <VTabsItems v-model="selectedTab">
                  <VTabItem
                    v-for="tab in activeTabNames"
                    :key="tab.text"
                    :value="tab.value"
                    :transition="false"
                    :data-test="`tab-content-` + tab.value"
                  >
                    <VContainer class="pa-0 py-6">
                      <template v-if="selectedTab==='tasks'">
                        <VRow>
                          <VCol cols="8">
                            <VRow v-if="unCompletedTask.length">
                              <VCol cols="12">
                                <TraineeTaskList
                                  :tasks="unCompletedTask"
                                  @change:state="completeTask"
                                />
                              </VCol>
                              <VCol cols="12">
                                <VDivider />
                              </VCol>
                            </VRow>
                            <template v-if="completedTask.length">
                              <VRow>
                                <VCol class="tt-text-title-2">
                                  {{ $t('completed') }}
                                </VCol>
                              </VRow>
                              <VRow>
                                <VCol>
                                  <TraineeTaskList
                                    :tasks="completedTask"
                                    @change:state="completeTask"
                                  />
                                </VCol>
                              </VRow>
                            </template>
                          </VCol>
                        </VRow>
                      </template>
                      <template v-if="selectedTab ==='profile'">
                        <VRow>
                          <VCol cols="8">
                            <ProfileAbout
                              :meta="user"
                              :is-another-profile="!!userId"
                            />

                            <template v-if="!userId">
                              <VDivider class="my-6" />
                              <ProfileRating
                                v-if="!hideRating"
                                :meta="user"
                              />
                            </template>
                          </VCol>
                        </VRow>
                      </template>
                      <template v-if="selectedTab ==='goals'">
                        <GoalsList
                          type="trainee"
                          :user-id="user.id"
                          :trainee-goals="user.purposes"
                        />
                      </template>
                    </VContainer>
                  </VTabItem>
                </VTabsItems>
              </tabswrapper>
            </VCol>
          </VRow>
        </VCol>
      </VRow>
    </VContainer>
  </div>
  <div v-else>
    <AppBarMobile>
      <VRow align="center">
        <VCol cols="1">
          <BackButton />
        </VCol>
        <VCol
          cols="10"
          class="text-center"
        >
          <h1
            data-test="profile-view-h1"
            class="tt-text-title-1 tt-light-mono-4--text"
          >
            {{ $t('menu.profile') }}
          </h1>
        </VCol>
      </VRow>
    </AppBarMobile>
    <VContainer
      v-if="user"
    >
      <VRow>
        <VCol cols="12">
          <ProfileHeader
            :level="user.levelName"
            :levels-completed="user.levelsCompleted"
            :photo-url="user.photoUrl"
            :first-name="user.firstName"
            :last-name="user.lastName"
            :position="user.staffPosition"
            :score="user.stat.bonus"
          />
        </VCol>
        <VCol cols="12">
          <TabsWrapper
            v-model="selectedTab"
            :tabs="activeTabNames"
            underline="none"
            data-test="tabs"
          >
            <VTabsItems v-model="selectedTab">
              <VTabItem
                v-for="tab in activeTabNames"
                :key="tab.text"
                :value="tab.value"
                :transition="false"
                :data-test="`tab-content-` + tab.value"
              >
                <VContainer>
                  <template v-if="selectedTab==='tasks'">
                    <VRow>
                      <VCol cols="12">
                        <VRow v-if="unCompletedTask.length">
                          <VCol cols="12">
                            <TraineeTaskList
                              :tasks="unCompletedTask"
                              @change:state="completeTask"
                            />
                          </VCol>
                          <VCol cols="12">
                            <VDivider />
                          </VCol>
                        </VRow>
                        <template v-if="completedTask.length">
                          <VRow>
                            <VCol class="tt-text-title-2">
                              {{ $t('completed') }}
                            </VCol>
                          </VRow>
                          <VRow>
                            <VCol>
                              <TraineeTaskList
                                :tasks="completedTask"
                                @change:state="completeTask"
                              />
                            </VCol>
                          </VRow>
                        </template>
                      </VCol>
                    </VRow>
                  </template>
                  <template v-if="selectedTab ==='profile'">
                    <VRow>
                      <VCol cols="12">
                        <ProfileAbout
                          :meta="user"
                          :is-another-profile="!!userId"
                        />

                        <template v-if="!userId">
                          <VDivider class="my-6" />
                          <ProfileRating
                            v-if="!hideRating"
                            :meta="user"
                          />
                        </template>
                      </VCol>
                    </VRow>
                  </template>
                  <template v-if="selectedTab ==='goals'">
                    <GoalsList
                      type="trainee"
                      :user-id="user.id"
                      :trainee-goals="user.purposes"
                    />
                  </template>
                </VContainer>
              </VTabItem>
            </VTabsItems>
          </TabsWrapper>
        </VCol>
      </VRow>
    </VContainer>
  </div>
</template>

<script>
import ProfileHeader from '@/components/profile/ProfileHeader.vue';
import * as snamiApi from '@/services/api/snami';
import ProfileAbout from '@/components/profile/ProfileAbout.vue';
import ProfileRating from '@/components/profile/ProfileRating.vue';
import { groupBy } from 'lodash';
import TraineeTaskList from '@/views/trainees/TraineeTaskList.vue';
import { TRAINEES_TASK_STATUSES } from '@/helpers/constants';
import GoalsList from '@/components/goals/GoalsList.vue';
import { mapGetters } from 'vuex';
import TabsWrapper from '@/components/shared/TabsWrapper.vue';
import AppBarMobile from '@/components/app/AppBarMobile.vue';
import BackButton from '@/components/shared/BackButton.vue';

export default {
  name: 'Id',
  components: {
    BackButton,
    AppBarMobile,
    TabsWrapper,
    TraineeTaskList,
    ProfileRating,
    ProfileAbout,
    ProfileHeader,
    GoalsList,
  },
  props: {
    userId: {
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {
      TRAINEES_TASK_STATUSES,
      loading: false,
      user: null,
      selectedTab: '',
      activeTabNames: [
        {
          text: this.$t('menu.tasks'),
          value: 'tasks',
        },
        {
          text: this.$t('menu.profile'),
          value: 'profile',
        },
        {
          text: this.$t('menu.goals'),
          value: 'goals',
        }],
      traineesList: [],
      userTasks: [],
      completedTask: [],
      unCompletedTask: [],
    };
  },
  computed: {
    ...mapGetters('settings', ['hideRating']),
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  watch: {
    userId: {
      handler() {
        this.getUserInfo();
      },
      immediate: true,
    },
  },
  methods: {
    async getUserInfo() {
      try {
        this.loading = true;
        await this.getTutorStaffList();
        this.user = this.traineesList.find((item) => item.id === +this.userId);
        const userTasks = this.user.checkpoints
          .map((checkpoint) => ({ ...checkpoint, date: this.$dayjs(checkpoint.date).format('LL') }))
          .sort((a, b) => (this.$dayjs(a.date).isAfter(this.$dayjs(b.date)) ? -1 : 1));
        const groupedUserTasks = groupBy(userTasks, 'date');
        this.userTasks = Object.entries(groupedUserTasks).map((item) => ({ date: item[0], data: [...item[1]] }));
        this.completedTask = this.userTasks
          .filter((taskGroup) => !taskGroup.data.some((task) => task.state === this.TRAINEES_TASK_STATUSES.PENDING));
        this.unCompletedTask = this.userTasks
          .filter((taskGroup) => taskGroup.data.some((task) => task.state === this.TRAINEES_TASK_STATUSES.PENDING));
      } catch (e) {
        // TODO обработчик ошибок
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },
    async completeTask({ id, state }) {
      try {
        this.loading = true;
        await snamiApi.tutorCheckpointCompletePost({ data: { id, state } });
        await this.getUserInfo();
      } catch (e) {
        // TODO обработчик ошибок
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },
    async getTutorStaffList() {
      try {
        const { data } = await snamiApi.tutorStaffListGet();
        this.traineesList = data;
      } catch (e) {
        // TODO обработчик ошибок
        console.warn(e);
      }
    },
  },
};
</script>

<style lang="scss">

</style>
